import { useCallback, useMemo } from 'react'
import { Controller, FieldError, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Box,
  FormControl,
  FormErrorMessage,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'

import { FormResponseMode, FormSchedule } from '~shared/types'

import { changeTimeOfTheDate } from '~utils/date'
import Button from '~components/Button'
import { DateRangeValue } from '~components/Calendar'
import { DateRangePicker } from '~components/DateRangePicker'
import { TimePicker } from '~components/TimePicker'
import Toggle from '~components/Toggle'

import { useMutateFormSettings } from '../mutations'
import { useAdminFormSettings } from '../queries'

import { SecretKeyActivationModal } from './SecretKeyActivationModal'

type FormScheduleToggleFormData = {
  dateRange: DateRangeValue
  openTime: string
  closeTime: string
}

const FormScheduleToggleBlock = (schedule: FormSchedule) => {
  const { i18n } = useTranslation()

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { isDirty, errors },
  } = useForm<FormScheduleToggleFormData>({
    defaultValues: {
      dateRange: [
        dayjs(schedule.openDate).toDate(),
        dayjs(schedule.closeDate).toDate(),
      ],
      openTime: dayjs(schedule.openDate).format('HH:mm'),
      closeTime: dayjs(schedule.closeDate).format('HH:mm'),
    },
  })

  const { mutateFormUpdateSchedule } = useMutateFormSettings()

  const handleFormScheduleSubmit = (
    formSchedule: FormScheduleToggleFormData,
  ) => {
    if (!isDirty) return

    // validate that both dates are set
    if (
      formSchedule.dateRange[0] === null ||
      formSchedule.dateRange[1] === null
    ) {
      setError('dateRange', {
        type: 'required',
        message: i18n.t('validate.fieldRequired'),
      })
      return
    }

    return mutateFormUpdateSchedule.mutate(
      {
        isEnable: schedule.isEnable,
        openDate: changeTimeOfTheDate(
          formSchedule.dateRange[0],
          formSchedule.openTime,
        ),
        closeDate: changeTimeOfTheDate(
          formSchedule.dateRange[1],
          formSchedule.closeTime,
        ),
      },
      {
        onSuccess: () => reset({}, { keepValues: true }),
      },
    )
  }

  return (
    <form onSubmit={handleSubmit(handleFormScheduleSubmit)}>
      <FormControl mt="2rem" isInvalid={!isEmpty(errors)}>
        <Stack my={{ base: '0.5rem', md: '0' }}>
          <Stack direction={{ base: 'column', md: 'row' }} spacing="1rem">
            <Stack direction="column" flex={{ base: 'auto', md: '2' }}>
              <Text>
                {i18n.t(
                  'features.adminForm.settings.components.formScheduleToggle.selectDateRange',
                )}
              </Text>
              <Controller
                control={control}
                name="dateRange"
                rules={{ required: true }}
                render={({ field: { value, onChange, ...field } }) => (
                  <DateRangePicker
                    value={value}
                    onChange={onChange}
                    allowManualInput={false}
                    {...field}
                  />
                )}
              />
              <FormErrorMessage
                display={{ md: 'none' }}
                fontSize="sm"
                margin="0"
                padding="0"
              >
                {errors.dateRange &&
                  (errors.dateRange as unknown as FieldError).message}
              </FormErrorMessage>
            </Stack>
            <Stack
              direction={{ base: 'row', md: 'column' }}
              flex={{ base: 'auto', md: '1' }}
            >
              <Text width={{ base: '7rem' }}>
                {i18n.t(
                  'features.adminForm.settings.components.formScheduleToggle.openTime',
                )}
              </Text>
              <Box height="100%">
                <Controller
                  name="openTime"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <TimePicker {...field} />}
                />
                <FormErrorMessage
                  display={{ md: 'none' }}
                  fontSize="sm"
                  margin="0"
                  padding="0"
                >
                  {errors.openTime?.type === 'required' &&
                    i18n.t('validate.fieldRequired')}
                </FormErrorMessage>
              </Box>
            </Stack>
            <Stack
              direction={{ base: 'row', md: 'column' }}
              flex={{ base: 'auto', md: '1' }}
            >
              <Text width={{ base: '7rem' }}>
                {i18n.t(
                  'features.adminForm.settings.components.formScheduleToggle.closeTime',
                )}
              </Text>
              <Box height="100%">
                <Controller
                  name="closeTime"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <TimePicker {...field} />}
                />
                <FormErrorMessage
                  display={{ md: 'none' }}
                  fontSize="sm"
                  margin="0"
                  padding="0"
                >
                  {errors.closeTime?.type === 'required' &&
                    i18n.t('validate.fieldRequired')}
                </FormErrorMessage>
              </Box>
            </Stack>
          </Stack>
          <Stack
            display={{ base: 'none', md: 'flex' }}
            direction={{ base: 'column', md: 'row' }}
            spacing="1rem"
            padding="0"
          >
            <Stack direction="column" flex={{ base: 'auto', md: '2' }}>
              <FormErrorMessage fontSize="sm" margin="0" padding="0">
                {errors.dateRange &&
                  (errors.dateRange as unknown as FieldError).message}
              </FormErrorMessage>
            </Stack>
            <Stack direction="column" flex={{ base: 'auto', md: '1' }}>
              <FormErrorMessage fontSize="sm" margin="0" padding="0">
                {errors.openTime?.type === 'required' &&
                  i18n.t('validate.fieldRequired')}
              </FormErrorMessage>
            </Stack>
            <Stack direction="column" flex={{ base: 'auto', md: '1' }}>
              <FormErrorMessage fontSize="sm" margin="0" padding="0">
                {errors.closeTime?.type === 'required' &&
                  i18n.t('validate.fieldRequired')}
              </FormErrorMessage>
            </Stack>
          </Stack>
          <Button
            colorScheme="primary"
            width={{ base: '30%', md: '20%' }}
            variant="outline"
            isLoading={mutateFormUpdateSchedule.isLoading}
            type="submit"
          >
            {i18n.t(
              'features.adminForm.settings.components.formScheduleToggle.save',
            )}
          </Button>
        </Stack>
      </FormControl>
    </form>
  )
}

export const FormScheduleToggle = () => {
  const { i18n } = useTranslation()

  const { data: settings, isLoading: isLoadingSettings } =
    useAdminFormSettings()

  const { responseMode } = settings ?? {}

  const storageModalProps = useDisclosure()
  const { onOpen: onOpenActivationModal } = storageModalProps

  const isFormSchedule = useMemo(
    () => settings && settings.schedule.isEnable,
    [settings],
  )

  const { mutateFormSchedule } = useMutateFormSettings()

  const handleToggleFormSchedule = useCallback(() => {
    if (!settings || isLoadingSettings || mutateFormSchedule.isLoading) return

    const nextEnableStatus = !settings.schedule.isEnable

    if (
      nextEnableStatus === true &&
      responseMode === FormResponseMode.Encrypt
    ) {
      return onOpenActivationModal()
    }

    return mutateFormSchedule.mutate({
      ...settings.schedule,
      isEnable: nextEnableStatus,
    })
  }, [
    settings,
    isLoadingSettings,
    mutateFormSchedule,
    responseMode,
    onOpenActivationModal,
  ])

  return (
    <Skeleton isLoaded={!isLoadingSettings && !!settings} mt="2em">
      <Toggle
        isLoading={mutateFormSchedule.isLoading}
        isChecked={isFormSchedule}
        description={i18n.t(
          'features.adminForm.settings.components.formDateToggle.openCloseFormDescription',
        )}
        label={i18n.t(
          'features.adminForm.settings.components.formDateToggle.openCloseForm',
        )}
        onChange={() => handleToggleFormSchedule()}
        marginBottom="2em"
      />
      {settings?.responseMode === FormResponseMode.Encrypt && (
        <SecretKeyActivationModal
          {...storageModalProps}
          publicKey={settings.publicKey}
          mode="formSchedulerActivation"
          schedule={settings.schedule}
        />
      )}
      {settings && settings.schedule.isEnable && (
        <FormScheduleToggleBlock {...settings.schedule} />
      )}
    </Skeleton>
  )
}
