import Translation from './types'

export const en: Translation = {
  translation: {
    utils: {
      fieldValidation: {
        verifyMobile: 'Please verify your mobile number',
        verifyEmail: 'Please verify your email address.',
        selectExactly: 'Please select exactly',
        selectAtLeast: 'Please select at least',
        selectAtMost: 'Please select at most',
        selectADate: 'Select a date',
        options: 'options',
        enterValidDate: 'Please enter a valid date',
        today: 'Today',
        todayOrBefore: 'Only dates today or before are allowed',
        todayOrAfter: 'Only dates today or after are allowed',
        notWithinRange: 'Selected date is not within the allowed date range',
        validNRIC: 'Please enter a valid NRIC',
        validUEN: 'Please enter a valid UEN',
        enter: 'Please enter',
        enterAtLeast: 'Please enter at least',
        enterAtMost: 'Please enter at most',
        character: 'characters',
        validDecimal: 'Please enter a valid decimal',
        validDecimalNoZero:
          'Please enter a valid decimal without leading zeros',
        decimalBetween: 'Please enter a decimal between',
        decimalGreaterOrEqual:
          'Please enter a decimal greater than or equal to',
        decimalLessOrEqual: 'Please enter a decimal less than or equal to',
        and: 'and',
        inclusive: '(inclusive)',
        digit: 'digits',
        validLandline: 'Please enter a valid landline number',
        dayNames: [
          { shortName: 'Su', fullName: 'Sunday' },
          { shortName: 'Mo', fullName: 'Monday' },
          { shortName: 'Tu', fullName: 'Tuesday' },
          { shortName: 'We', fullName: 'Wednesday' },
          { shortName: 'Th', fullName: 'Thursday' },
          { shortName: 'Fr', fullName: 'Friday' },
          { shortName: 'Sa', fullName: 'Saturday' },
        ],
        monthNames: [
          { fullName: 'January', shortName: 'Jan' },
          { fullName: 'February', shortName: 'Feb' },
          { fullName: 'March', shortName: 'Mar' },
          { fullName: 'April', shortName: 'Apr' },
          { fullName: 'May', shortName: 'May' },
          { fullName: 'June', shortName: 'Jun' },
          { fullName: 'July', shortName: 'Jul' },
          { fullName: 'August', shortName: 'Aug' },
          { fullName: 'September', shortName: 'Sep' },
          { fullName: 'October', shortName: 'Oct' },
          { fullName: 'November', shortName: 'Nov' },
          { fullName: 'December', shortName: 'Dec' },
        ],
      },
    },
    dialog: {
      areYouSureTitle: 'Are you sure?',
      areYouSureBody: 'Are you sure you want to perform this action?',
      areYouSureApproveBody:
        'Are you sure you want to approve this form submission?',
      areYouSureRejectBody:
        'Are you sure you want to reject this form submission?',
      yes: 'Yes',
      no: 'No',
    },
    activateSecretKey: {
      activate: 'Activate your form',
      enterOrUpload: 'Enter or upload Secret Key',
      loseKey:
        'If I lose my key, I will not be able to activate my form and all my responses will be lost permanently',
      activateForm: 'Activate form',
      secretKeyProvided: 'The secret key provided is invalid',
      selectedFile: 'Selected file seems to be invalid',
      invalid: 'The secret key provided is invalid',
      enterKey: "Please enter the form's secret key",
      enterKeyToContinue: 'Enter or upload your Secret Key to continue',
    },
    settingMutations: {
      formOpen: 'Your form is now open.',
      storeSecretKey:
        'Store your secret key in a safe place. If you lose your secret key, all your responses will be lost permanently.',
      autoArchive: 'AutoArchive your mailbox',
      largeResponse: 'If you expect a large number of responses, ',
      avoidLost: ' to avoid losing any of them.',
      formClose: 'Your form is closed to new responses.',
      autoFormClose:
        'Your form will now automatically close on the { formLimit, selectordinal, zero {#th} one {#st} two {#nd} few {#rd} many {#th} other {#th}} submissions',
      submissionLimit: 'The submission limit on your form is removed.',
      recaptcha: 'reCAPTCHA is now ',
      enable: 'enabled',
      disable: 'disabled',
      onYourForm: ' on your form.',
      titleUpdate: "Your form's title has been updated.",
      inactive: "Your form's inactive message has been updated.",
      email: 'Emails successfully updated.',
      eservice: 'E-service ID successfully updated.',
      formAuth: 'Form authentication successfully updated.',
      webhook: 'Webhook URL successfully ',
      update: 'updated',
      remove: 'removed',
      webhookRetry: 'Webhook retries have been ',
      enableWithDot: 'enabled.',
      disableWithDot: 'disabled.',
      twilio: 'Updated Twilio credentials',
      deleteTwilio: 'Deleted Twilio credentials',
    },
    responsePage: {
      responseID: 'Response ID:',
      timestamp: 'Timestamp:',
      status: 'Status:',
      attachment: 'Attachment:',
      backtolist: 'Back to list',
      respondent: 'Respondent',
      download: 'Download',
      csv: 'CSV only',
      csvAttachment: 'CSV with attachments',
      noResponse: 'No responses to download',
      response: 'response',
      s: 's',
      was: 'was',
      were: 'were',
      decrypted: 'decrypted',
      responseProcess:
        ' responses are being processed. Navigating away from this page will stop the download.',
      stopDownload: 'Stop downloading responses?',
      stopDownloadDescript:
        'You are currently downloading form responses. The download will be aborted if you leave this page.',
      leavePage: 'Yes, leave this page',
      downloadCancel: 'Responses download has been canceled.',
      downloadOption: 'Download options',
      downloadFail: 'Failed to start download. Please try again later.',
      downloadResponse: 'Download responses and attachments',
      beta: 'beta',
      separateZip: 'Separate zip files will be downloaded, ',
      eachResponse: 'one for each response',
      adjust: 'You can adjust the date range before proceeding.',
      numberOfResponse: 'Number of responses and attachments:',
      estimate: 'Estimated time:',
      perResponse: '30-50 mins per 1,000 responses',
      intensive: 'Downloading many attachments can be an intensive operation.',
      IE: 'Do not use Internet Explorer',
      networkConnect: 'Ensure network connectivity is strong',
      sufficient: 'Ensure device has sufficient disk space for the download',
      cancel: 'Cancel',
      startDownload: 'Start download',
      dateRange:
        'The date range you selected does not contain any responses. Please select a date range containing responses and try again.',
      errorRetrieve:
        'There was an error retrieving your form. Please try again later.',
      allRes: 'All responses',
      andAttachment: ' and attachments',
      downloadSuccess: ' have been downloaded successfully.',
      downloadComplete: 'Download complete',
      backToResponse: 'Back to responses',
      filename: 'Filename:',
      responseAndAttachment:
        'responses and attachments have been downloaded successfully, refer to downloaded CSV file for more details',
      responseNoAttachment:
        'responses have been downloaded successfully, refer to downloaded CSV file for more details',
      responseAndAttachmentCannotDownload:
        'responses and attachments could not be downloaded.',
      responseNoAttachmentCannotDownload: 'responses could not be downloaded.',
      partialSuccess: 'Partial success.',
      decryptedRes: 'responses were decrypted.',
      failed: 'failed.',
      success: 'Success.',
      downloading: 'Downloading...',
      stopDownloading: 'Stop download',
      completed: 'completed',
      upto: 'Up to ',
      navigating:
        'files are being downloaded into your destination folder. Navigating away from this page will stop the download.',
    },
    ownership: {
      owner: 'Owner',
      editor: 'Editor',
      viewer: 'Viewer',
    },
    banner: {
      header: 'header',
      image: 'image',
      paragraph: 'paragraph',
      field: 'field ',
      delete: ' was deleted.',
      thefield: 'The ',
      create: ' was created.',
      duplicate: ' was duplicated.',
      update: ' was updated.',
      cannotDelete:
        'Something went wrong when deleting your field. Please refresh and try again.',
      cannotCreate:
        'Something went wrong when creating your field. Please refresh and try again.',
      cannotDuplicate:
        'Something went wrong when duplicating your field. Please refresh and try again.',
      cannotUpdate:
        'Something went wrong when editing your field. Please refresh and try again.',
    },
    validate: {
      fieldRequired: 'This field is required',
      invalidEmail: 'Please enter a valid email',
      invalidDomain:
        'The entered email does not belong to an allowed email domain',
      invalidDropDown: 'Entered value is not a valid dropdown option',
      invalidNumber: 'Please enter a valid mobile number',
      invalidSecretKey: 'The secret key provided is invalid',
      enterSecretKey: "Please enter the form's secret key",
      pleaseEnterNumberOfCharacters: 'Please enter number of characters',
      pleaseEnterNumberOfNumberOfCharacters:
        'Please enter number of characters',
    },
    feedback: {
      shareFeedback: 'Share your feedback',
      exp: 'How was your experience using FormKH?',
      rating: '1: Poor, 5: Excellent',
      anyFeedback: 'Do you have any other feedback for us?',
      submit: 'Submit feedback',
      cancel: 'Cancel',
      export: 'Export',
      feedbackDownload: 'Form feedback download started',
    },
    footer: {
      builtBy: 'Built by',
      dgc: 'Digital Government Committee',
      contact: 'Contact',
      email: 'info@mptc.gov.kh',
      location:
        'Building 13, Monivong Blvd, Sangkat Srah Chak, Khan Daun Penh, Phnom Penh, Cambodia, 120210',
      privacy: 'Privacy',
      termsOfUse: 'Terms of use',
    },
    landingPage: {
      headTitle: 'Build Forms in just minutes',
      headTitleColor: ' Securely and Efficiently !',
      instant:
        'Instant, customisable forms with zero code or cost, to safely collect classified and sensitive data.',
      startBuilding: 'Start building your form now',
      helpCenterHeader: 'User Guide',
      helpCenterDescription:
        'FormKH is an online form builder that allows users to create custom forms for various purposes such as surveys, registrations, feedback, refinements and more.',
      helpCenter: 'Learn more',
      formAndData: 'Your form building and data collection features',
      dragAndDrop: 'Drag and drop builder',
      dragAndDropDescript:
        'Launch forms in minutes with the drag-and-drop builder, and over 20 types of fields to choose from.',
      conditionalLogic: 'Conditional logic',
      conditionalLogicDescript:
        'Create dynamic forms that show or hide specific fields based on previous responses.',
      emailConfirmation: 'Email confirmation',
      emailConfirmationDescript:
        'Send confirmation emails to your respondents along with a copy of their responses.',
      prefill: 'Prefill',
      prefillDescript:
        'Make form filling faster for respondents by pre-filling fields for them.',
      webhooks: 'Webhooks',
      webhooksDescript:
        'Send form responses to external applications in real time.',
      automaticScheduler: 'Scheduler',
      automaticSchedulerDescription:
        'Set the schedule to automatically open and close your form.',
      usedBy: 'Used by these government agencies',
      secureCollect: 'Secure collection of responses',
      encrypted:
        'All form responses are encrypted end-to-end. This means third parties, including FormKH, will not be able to access or view your form data.',
      storageMode: 'STORAGE MODE',
      sensitive: 'Sensitive (Normal)',
      howItWorks: 'How it works',
      viewResponse:
        'View your responses within FormKH. All data is end-to-end encrypted, which means third parties, including FormKH, will not be able to access or view your form data.',
      step1: 'Log in to FormKH via internet',
      step2: 'Create a new Storage mode form and store Secret Key safely',
      step3: 'Upload Secret Key and view your responses',
      step4: 'Download your responses as a CSV',
      step1Header: 'Login',
      step2Header: 'Create Form',
      step3Header: 'Upload Secret Key',
      step4Header: 'Download',
      step1Index: '1',
      step2Index: '2',
      step3Index: '3',
      step4Index: '4',

      startBuildingForm: 'Start building your form now',
      getStarted: 'Get started for free',
    },

    NotFoundError: {
      NotFoundErrorPage: {
        notFoundErrorPage: 'This page could not be found.',
        back: 'Back',
        goToDashboard: 'Dashboard',
      },
    },

    app: {
      adminNavBar: {
        adminNavBar: {
          billing: 'Billing',
          emergencyContact: 'Emergency contact',
          logout: 'Log out',
        },
      },
      mixed: {
        others: 'ផ្សេងៗ / Others',
        submit: 'បញ្ជូន / Submit now',
      },
    },
    templates: {
      Field: {
        checkbox: {
          checkboxField: {
            specifyValue: 'Please specify a value for the "others" option',
          },
        },
        radio: {
          radioField: {
            specifyValue: 'Please specify a value for the "others" option',
          },
        },
        image: {
          imageField: {
            imageNotProvided: 'Image not provided',
            cannotDisplay: 'This image could not be displayed',
          },
        },
        table: {
          AddRowFooter: {
            row: '{ currentRows, plural, =0 {no rows} =1 {1 row} other {# rows} }',
            currentRowsOutOfMaxRow:
              '{ currentRows } out of max { maxRows, plural, =0 {no rows} =1 {1 row} other {# rows} }',
          },
        },
      },
      navigationPrompt: {
        unsavedChangesModal: {
          title: 'You have unsaved changes',
          description:
            'Are you sure you want to leave? Your changes will be lost.',
          confirmButtonText: 'Yes, discard changes',
          cancelButtonText: 'No, stay on page',
        },
      },
      resendOtpButton: {
        resendOTP: 'Resend OTP',
        in: ' in',
      },
    },
    features: {
      user: {
        mutations: {
          emergencyContact: 'Emergency contact added.',
        },
      },
      rolloutAnnouncement: {
        newFeatureTag: {
          newFeat: 'New feature',
        },
        rolloutAnnouncementModal: {
          cancel: 'Cancel',
          done: 'Done',
          next: 'Next',
        },
      },
      verifiableFields: {
        components: {
          Checkbox: {
            others: 'Others',
          },
          VerificationBox: {
            constants: {
              verifyMobile: 'Verify your mobile number',
              mobileCode:
                'An SMS with a 6-digit verification code was sent to you. It will be valid for 30 minutes.',
              verifyEmail: 'Verify your email',
              emailCode:
                'An email with a 6-digit verification code was sent to you. It will be valid for 30 minutes.',
            },
            verificationBox: {
              otpIsRequired: 'OTP is required.',
              onlyNumber: 'Only numbers are allowed.',
              otp: 'Please enter a 6 digit OTP.',
              submitOTP: 'Submit OTP for verification',
              submit: 'Submit',
            },
          },
          VerifiableFieldContainer: {
            verifiableFieldContainer: {
              givenEmailVerified: 'Given email address is verified',
              givenMobileVerified: 'Given mobile number is verified',
              verifyEmail: 'Verify email address',
              verifyMobile: 'Verify mobile number',
              verify: 'Verify',
              verified: 'Verified',
            },
          },
        },
        VerifiableFieldProvider: {
          pleaseFillInTheFieldBeforeAttemptingVerification:
            'Please fill in the field before attempting verification',
        },
      },
      publicForm: {
        components: {
          FormFields: {
            submissionDisabled: 'Submission disabled',
            submit: 'Submit now',
            submitting: 'Submitting',
            theHighlightedFieldsInThisFormHaveBeenPrefilledEtc:
              'The highlighted fields in this form have been pre-filled according to the link that you clicked. Please check that these values are what you intend to submit, and edit if necessary.',
            FormFieldsContainer: {
              thisFormIsJustATemplateYouAreNotAbleToSubmitThisForm:
                'This form is just a template. You are not able to submit this form.',
            },
            FormSectionsContext: {
              instructions: 'Instructions',
            },
          },
          FormInstructions: {
            instructions: 'Instructions',
          },
          FormEndPage: {
            formExp: 'How was your form filling experience today?',
            tellUs: 'Tell us more about your experience',
            submitFeedback: 'Submit feedback',
            rating: 'Please select a rating',
            components: {
              EndPageBlock: {
                responseID: 'Response ID:',
                successfullySubmitFor:
                  'You have successfully submitted your response for',
                successfullySubmit:
                  'You have successfully submitted your response',
              },
            },
            FormEndPageContainer: {
              thankYouForSubmittingPreview:
                'Thank you for submitting your feedback! Since you are in preview mode, the feedback is not stored.',
              thankYouForSubmitting: 'Thank you for submitting your feedback!',
            },
          },
          FormLogo: {
            FormBannerLogo: {
              imageNotProvided: 'Image not provided',
              imageNotFound: 'Image not found',
            },
          },
          FormNotFound: {
            FormNotFound: {
              formNotFound: 'This form is not available.',
              FormNotFoundDescription:
                'If you think this is a mistake, please contact the agency that provided you with this form.',
            },
          },
          FormStartPage: {
            estimatedTimeToComplete:
              '{ estTimeTake, plural, =0 {# min} =1 {# min} other {# mins}} estimated time to complete',
          },
          FormInstruction: {
            instruction: 'Instructions',
          },
          SectionSidebar: {
            skipToSection: 'Skip to section',
          },
        },
      },
      AppPublicHeader: {
        help: 'Help',
        login: 'Login',
      },
      env: {
        adminFeedbackIcon: 'Have feedback?',
      },
      formTemplates: {
        FormTemplatesContent: {
          FormTemplatesHeader: {
            allFormTemplatesTitle: 'All Form Templates',
          },
          FormTemplatesRow: {
            FormTemplatesRowsFilterNone: {
              noTemplateFormFound: 'No template forms found',
              tryAnotherSearchOrRemoveFilters:
                'Try another search or remove filters',
            },
          },
        },
      },
      adminForm: {
        share: {
          shareFormModal: {
            formClosed:
              'This form is currently closed to new responses. Activate your form in',
            settings: 'Settings',
            allowResp: 'to allow new responses or to share it as a template.',
            shareLink: 'Share link',
            copyRespLink: 'Copy respondent form link',
            shareTemplate: 'Share template',
            copyLink: 'Copy link to use this form as a template',
            embedHTML: 'Embed HTML',
            copyHTML: 'Copy HTML code for embedding this form',
            createLink:
              'Create an official short link and QR code with [Go.gov.kh](https://form.gov.kh) and share it over the Internet.',
            shareForm: 'Share form',
          },
        },
        common: {
          mutation: {
            updateThank: 'The Thank you page was updated.',
            noFormIDProvided: 'No formId provided to useMutateCollaborators',
            collaborator:
              'The collaborator was unable to be added or edited. Please try again or refresh the page.',
            errorOccur:
              'Sorry, an error occurred. Please refresh the page and try again later.',
            errorAdd: 'Error adding collaborator.',
            errorUpdate: 'Error updating collaborator.',
            errorRemove: 'Error removing collaborator.',
            errorRemovingSelf: 'Error removing self.',
            errorTransfer: 'Error transfering form ownership.',
            error: 'Error',
            whitelisted: ' is not part of a whitelisted agency',
            error422: 'An unexpected error 422 happened',
            updateNotExist:
              'Collaborator to update does not seem to exist. Refresh and try again.',
            updated:
              '{ permissionToUpdateEmail } has been updated to the { newPermission } role',
            role: ' role',
            added:
              '{ newPermissionEmail } has been added as { permissionRole }',
            remove: ' has been removed as a collaborator',
            owner: ' is now the owner of this form',
            removeSelf:
              'You have removed yourself as a collaborator from the form.',
            formID: 'No formId provided',
            editHeaderAndInstructions:
              'The form header and instructions were updated.',
          },
          components: {
            PreviewFormBanner: {
              editForm: 'Edit form',
              formPreview: 'Form Preview',
              templatePreview: 'Template Preview',
              back: 'Back',
              useThisTemplates: 'Use this Template',
            },
            AdminFormNavBar: {
              AdminFormNavBar: {
                create: 'Create',
                settings: 'Settings',
                results: 'Results',
                previewForm: 'Preview form',
                shareFormLink: 'Share form link',
                manageCollaborators: 'Manage collaborators',
                saveAt: 'Saved at',
              },
              AdminFormNavbarBreadcrumbs: {
                allForms: 'All forms',
              },
            },
            DirtyModal: {
              noReturnToEditing: 'No, return to editing',
            },
            collaboratorModal: {
              addCollab: 'Add collaborators or transfer form ownership',
              share:
                'Share your secret key with users who need to access response data',
              add: 'Add collaborator',
              transfer: 'Transfer form ownership',
              manageCollab: 'Manage collaborators',
              collab: 'Collaborators',
              collabEmailRequired: 'Collaborator email is required',
              validEmail: 'Please enter a valid email',
              existingCollab:
                'This user is an existing collaborator. Edit role below.',
              cannotAddOwner: 'You cannot add the form owner as a collaborator',
              RemoveMyselfScreen: {
                removeMyselfAsCollaborator: 'Remove myself as collaborator',
                youAreRemovingYourselfAsACollaboratorAndWillLoseAllAccessEtc:
                  'You are removing yourself as a collaborator and will lose all access to this form. This action cannot be undone.',
                yesRemoveMyself: 'Yes, remove myself',
                cancel: 'Cancel',
              },
              TransferOwnershipScreen: {
                transferFormOwnership: 'Transfer form ownership',
                youAreTransferringThisFormTo:
                  'You are transferring this form to',
                youWillLoseFormOwnershipAndTheRightToDeleteEtc:
                  '. You will lose form ownership and the right to delete this form. You will still have Editor rights.',
                yesTransferForm: 'Yes, transfer form',
                cancel: 'Cancel',
              },
            },
          },
        },
        create: {
          builderAndDesign: {
            GetMutationMessage: {
              field: 'field',
            },
            DeleteFieldModal: {
              useInLogic:
                'This field is used in your form logic, so deleting it may cause your logic to stop working correctly. Are you sure you want to delete this field?',
              deletePrompt:
                'Are you sure you want to delete this field? This action cannot be undone.',
              cancel: 'Cancel',
              deleteField: 'Yes, delete field',
              deleteTitle: 'Delete field',
              statement: 'Statement',
            },
            BuilderAndDesignContent: {
              webhooksAreEnabledOnThisFormEtc:
                'Webhooks are enabled on this form. Please ensure the webhook server is able to handle any field changes.',
              BuilderAndDesignPlaceholder: {
                EmptyFormPlaceholder: {
                  tapHereToAddAField: 'Tap here to add a field',
                  dragAFieldFromTheBuilderOnTheLeftToStart:
                    'Drag a field from the Builder on the left to start',
                  dropYourFieldHere: 'Drop your field here',
                },
              },
              FieldRow: {
                FieldRowContainer: {
                  theFieldTitleCouldNotBeDuplicatedEtc:
                    "The field { fieldTitle } could not be duplicated. The attachment size of **{ thisAttachmentSize } MB** exceeds the form's remaining available attachment size of **{ remainingAvailableSize } MB**.",
                  thisFieldMayBeHiddenByYourFormLogic:
                    'This field may be hidden by your form logic',
                },
              },
              FormBuilder: {
                customiseThankYouPage: 'Customise Thank you page',
              },
            },
            BuilderAndDesignDrawer: {
              EditFieldDrawer: {
                editFieldType: {
                  common: {
                    createField: 'Create field',
                    saveField: 'Save field',
                    edit: 'Edit ',
                    pleaseProvideAtLeast1Option:
                      'Please provide at least 1 option.',
                    pleaseRemoveDuplicateOptions:
                      'Please remove duplication options.',
                    pleaseRemoveDuplicateOthersOptions:
                      "Please remove duplicate 'Others' options.",
                    pleaseProvideOptionsBetweenTheLimit:
                      'Please provide options between the limit.',
                  },
                  EditAttachment: {
                    youHaveExceededYourFormsAttachmentSizeLimitOfMB:
                      "You have exceeded your form's attachment size limit of { maxTotalSizeMb } MB",
                    AttachmentStackedbar: {
                      used: 'Used',
                    },
                  },
                  EditCheckbox: {
                    enterOneOptionPerLine: 'Enter one option per line',
                    customMinValidationOptions: {
                      minimumCannotBeLargerThanMaximum:
                        'Minimum cannot be larger than maximum',
                      cannotBeMoreThanNumberOfOptions:
                        'Cannot be more than number of options',
                      pleaseEnterSelectionLimits:
                        'Please enter selection limits',
                      cannotBeLessThan1: 'Cannot be less than 1',
                      cannotBeMoreThan10000: 'Cannot be more than 10,000',
                    },
                  },
                  EditDate: {
                    customMinValidationOptions: {
                      youMustSpecifyAtLeastOneDate:
                        'You must specify at least one date.',
                      pleaseEnterAValidDate: 'Please enter a valid date',
                      maxDateCannotBeLessThanMinDate:
                        'Max date cannot be less than min date.',
                    },
                  },
                  EditDecimal: {
                    maximumValueCannotBeSmallerThanTheMinimumValue:
                      'Maximum value cannot be smaller than the minimum value.',
                  },
                  EditDropdown: {
                    enterOneOptionPerLine: 'Enter one option per line',
                  },
                  EditEmail: {
                    pleaseRemoveDuplicateEmailDomains:
                      'Please remove duplicate email domains',
                    pleaseEnterOnlyValidEmailDomainsStartWithAt:
                      'Please enter only valid email domains starting with @',
                  },
                  EditImage: {
                    pleaseUploadAnImage: 'Please upload an image',
                  },
                  EditLikert: {
                    pleaseEnterBetweenLimit:
                      'Please enter between { min } and { max } options.',
                  },
                  EditLongText: {
                    pleaseEnterAValidNumber: 'Please enter a valid number',
                    cannotBeLessThan1: 'Cannot be less than 1',
                    cannotBeMoreThan10000: 'Cannot be more than 10000',
                  },
                  EditNumber: {
                    customMinValidationOptions: {
                      cannotBeLessThan1: 'Cannot be less than 1',
                      cannotBeMoreThan10000: 'Cannot be more than 10000',
                    },
                  },
                  EditRadio: {
                    enterOneOptionPerLine: 'Enter one option per line',
                  },
                  EditTable: {
                    minimumRowsMustBeGreaterThan0:
                      'Minimum rows must be greater than 0',
                    maximumRowsMustBeGreaterThanMinimumRows:
                      'Maximum rows must be greater than minimum rows',
                    maximumRowsMustBeLessThanOrEqualTo:
                      'Maximun rows must be less than or equal to {limit}',
                  },
                  EditTableDropdown: {
                    options: 'Options',
                    pleaseRemoveDuplicateOptions:
                      'Please remove duplicate options.',
                  },
                  EditTableDropdownInput: {
                    enterOneOptionPerLine: 'Enter one option per line',
                  },
                  EditShortText: {
                    question: 'Question',
                    description: 'Description',
                    required: 'Required',
                    numberOfCharacters: 'Number of characters',
                    numberOfCharactersAllowed: 'Number of characters allowed',
                    enablePrefill: 'Enable pre-fill',
                    enablePrefillDescription:
                      'Use the Field ID to pre-populate this field for your users via an URL. [Learn how]',
                    fieldIDWillBeGeneratedAfterThisFieldIsSaved:
                      'Field ID will be generated after this field is saved',
                    copyFieldIDValue: 'Copy field ID Value',
                    pleaseEnterAValidNumber: 'Please enter a valid number',
                    cannotBeLessThan1: 'Cannot be less than 1',
                    cannotBeMoreThan10000: 'Cannot be more than 10000',
                  },
                  EditFieldType: {
                    editAttachment: {
                      viewOurList: 'View our [complete list]',
                      acceptedFileType:
                        'of accepted file types. Please also read our [FAQ on email reliability]',
                      unacceptedFile: ' relating to unaccepted file types.',
                    },
                    description: 'Description',
                    question: 'Question',
                    statements: 'Statements',
                    options: 'Options',
                    required: 'Required',
                    numValid: 'Number validation',
                    minVal: 'Minimum value',
                    maxVal: 'Maximum value',
                    numOfCharAllowed: 'Number of characters allowed',
                    numOfChar: 'Number of characters',
                    numberOfDigits: 'Number of characters',
                    others: 'Others',
                    selectLimit: 'Selection Limits',
                    customNum:
                      'Customise the number of options that users are allowed to select',
                    min: 'Minimum',
                    max: 'Maximum',
                    sectHeading: 'Section heading',
                    paragraph: 'Paragraph',
                    numOfSteps: 'Number of steps',
                    shape: 'Shape',
                    otpVerify: 'OTP verification',
                    otpDescript:
                      'Respondents must verify by entering a code sent to them',
                    restrictEmail: 'Restrict email domains',
                    restrictDescript:
                      'OTP verification needs to be enabled first',
                    domainAllowed: 'Domains allowed',
                    emailConfirm: 'Email confirmation',
                    emailDescript:
                      'Customise an email acknowledgement to respondents',
                    subject: 'Subject',
                    senderName: 'Sender Name',
                    content: 'Content',
                    includePDF: 'Include PDF response',
                    allowInternationNum: 'Allow international numbers',
                    otpDescription:
                      'Respondents must verify by entering a code sent to them. If you have added Twilio credentials, please test this OTP verification feature to make sure your credentials are accurate.',
                    dateValidate: 'Date validation',
                    uploadImg: 'Uploaded image',
                    minimumRows: 'Minimum rows',
                    allowToAddRows: 'Allow respondent to add more rows',
                    maximumRowsAllowed: 'Maximum rows allowed',
                    maxSize: 'Maximum size of individual attachment',
                    optional: '(optional)',
                    defaultSender: 'Default sender name is your agency name',
                    defaultEmail: 'Default email body',
                    defaultEmailSubject: 'Default email subject',
                    selectOption: 'Select an option',
                    pdfDescript:
                      'For security reasons, PDF responses are not included in email confirmations for Storage mode forms',
                    addColumn: 'Add column',
                    addAnotherRow: 'Add another row',
                    col: 'Column ',
                    maxFileSize: 'Maximum file size',
                    chooseFile: 'Choose file',
                    dragDrop: 'or drag and drop here',
                    dropFileHere: 'Drop the file here...',
                    delField: 'Delete field',
                    dupField: 'Duplicate field',
                    yes: 'Yes',
                    no: 'No',
                    verify: 'Verify',
                  },
                  SMSCountsModal: {
                    formProvides: 'Form provides ',
                    freeOTP:
                      ' free SMS OTP verifications per account, for forms you are an owner of. Once this limit is reached, SMS OTP verification will be automatically disabled for all owned forms. Forms with Twilio already set up will not be affected.',
                    ensureEnoughVerify:
                      "If you are a collaborator, ensure the form's owner has enough free verifications. If you require more than",
                    verifications: 'verifications, please',
                    twilioCredentials: 'add your Twilio credentials.',
                    understand: 'Yes, I understand',
                    verifySMS: 'Verified SMS Billing',
                  },
                },
              },

              DesignDrawer: {
                logo: 'Logo',
                rDefault: 'Default',
                rNone: 'None',
                uploadCustomLogo: 'Upload custom logo (jpg, png, or gif)',
                uploadCustomLogoBanner:
                  'Upload custom banner (jpg, png, or gif)',
                uploadCustomLogoOrBanner:
                  'Upload custom logo or banner (jpg, png, or gif)',
                themeColor: 'Theme colour',
                timeToComplete: 'Time taken to complete form (minutes)',
                formInstruct: 'Instructions for your form',
                saveDesign: 'Save Design',
                cancel: 'Cancel',
                editHeader: 'Edit header and instructions',
                cannotBeLessThan1: 'Cannot be less than 1',
                cannotBeMoreThan1000: 'Cannot be more than 1000',
                pleaseUploadABanner: 'Please upload a banner',
                theBannerSizeMustBePxPx:
                  'The banner size must be 1200px x 300px.',
                pleaseUploadALogo: 'Please upload a logo',
              },

              FieldListDrawer: {
                fields: 'Fields',
                basic: 'Basic',
                myInfo: 'MyInfo',
                myInfoFieldsAreNotAvailableInStorageModeForms:
                  'MyInfo fields are not available in Storage mode forms.',
                enableMyInfoInTheSettingsTabToAccessTheseFields:
                  'Enable MyInfo in the Settings tab to access these fields.',
                learnMore: 'Learn more',
                personal: 'Personal',
                contact: 'Contact',
                particulars: 'Particulars',
                family: 'Family (Marriage)',
              },
            },
          },
          common: {
            CreatePageSidebar: {
              addFields: 'Add fields',
              editHeaderAndInstructions: 'Edit header and instructions',
              addLogic: 'Add logic',
              editThankYouPage: 'Edit Thank you page',
              help: 'Help',
            },
          },
          constants: {
            BasicFieldToDrawerMeta: {
              Image: 'Image',
              Paragraph: 'Paragraph',
              Heading: 'Heading',
              Attachment: 'Attachment',
              Checkbox: 'Checkbox',
              Date: 'Date',
              Decimal: 'Decimal',
              Dropdown: 'Dropdown',
              Email: 'Email',
              'Home number': 'Home number',
              'Long answer': 'Long answer',
              'Mobile number': 'Mobile number',
              NRIC: 'NRIC',
              Number: 'Number',
              Radio: 'Radio',
              Rating: 'Rating',
              'Short answer': 'Short answer',
              Table: 'Table',
              UEN: 'UEN',
              'Yes/No': 'Yes/No',
              Likert: 'Likert',
            },
            MyInfoAttribute: {
              Name: 'Name',
              Gender: 'Gender',
              'Date of Birth': 'Date of Birth',
              Race: ' Race',
              Nationality: ' Nationality',
              'Birth Country': ' Birth Country',
              'Residential Status': 'Residential Status',
              Dialect: 'Dialect',
              'Housing Type': 'Housing Type',
              'HDB Type': 'HDB Type',
              'Passport Number': 'Passport Number',
              'Passport Expiry Date': 'Passport Expiry Date',
              'Vehicle Number': 'Vehicle Number',
              'Registered Address': 'Registered Address',
              'Mobile Number': 'Mobile Number',
              Occupation: 'Occupation',
              'Name of Employer': 'Name of Employer',
              'Workpass Status': 'Workpass Status',
              'Workpass Expiry Date': 'Workpass Expiry Date',
              'Marital Status': 'Marital Status',
              'Country of Marriage': 'Country of Marriage',
              'Marriage Certificate Number': 'Marriage Certificate Number',
              'Marriage Date': 'Marriage Date',
              'Divorce Date': 'Divorce Date',
            },
            constantsts: {
              yesNo: 'Yes/No',
            },
          },
          featureTour: {
            title: 'Tips',
            constants: {
              addFields: {
                title: 'Add fields',
                content:
                  'Add fields to your form by dragging and dropping them.',
              },
              editHeaderAndInstructions: {
                title: 'Edit header and instructions',
                content:
                  'Change your agency logo, theme colours, layout and add instructions to your form.',
              },
              addLogic: {
                title: 'Add logic',
                content:
                  'Decide what fields or sections a user sees based on their input.',
              },
              editThankYouPage: {
                title: 'Edit Thank you page',
                content:
                  'Customise your thank you message and add follow-up instructions that users can see after they submit your form.',
              },
            },
            buttons: {
              next: 'Next',
              done: 'Done',
            },
          },
          logic: {
            mutations: {
              updateLogic: 'The logic was successfully updated',
              deleteLogic: 'The logic was successfully deleted',
              createLogic: 'The logic was successfully created',
            },
            components: {
              emptyLogic: {
                startLogic: 'Start creating logic for your form',
                showHide:
                  'Show or hide fields depending on user input, or disable form submission for invalid answers.',
                learnLogic: ' Learn to work with logic',
                addLogic: 'Add logic',
                allowedField: 'Allowed fields',
              },
              logicContent: {
                logic: 'Logic',
                testForm:
                  'Please test your form thoroughly to ensure the logic works as expected.',
                allowedField: 'Allowed fields',
                cancel: 'Cancel',
                thereAreErrorsInYourFormsLogicPleaseFixThemBeforeSharingYourForm:
                  "There are errors in your form's logic, please fix them before sharing your form",
                EditLogicBlock: {
                  EditCondition: {
                    EditionConditionBlockDivider: {
                      and: 'AND',
                    },
                  },
                },
              },
              addConditionDivider: {
                addCond: 'Add condition',
              },
              editConditionBlock: {
                fieldIsInvalidOrUnableToAcceptLogic:
                  'Field is invalid or unable to accept logic.',
                if: 'IF',
                is: 'IS',
                logicCriteria: 'Logic criteria',
                others: 'Others',
                pleaseEnterAValidDecimal: 'Please enter a valid decimal',
                pleaseEnterLogicCriteria: 'Please enter logic criteria.',
                pleaseSelectACondition: 'Please select a condition.',
                pleaseSelectAQuestion: 'Please select a question.',
                removeLogicBlock: 'Remove logic condition block',
                selectAQuestion: 'Select a question',
                thisFieldWasDeletedPleaseSelectAnotherField:
                  'This field was deleted, please select another field',
                plusMore: '+{number} more',
              },
              ThenShowBlock: {
                allFieldsWereDeletedPleaseSelectAtLeastOneField:
                  'All fields were deleted, please select at least one field',
                then: 'Then',
                beenRemoved: 'been removed from your logic',
                customMessage:
                  'Custom message to be displayed when submission is prevented',
                show: 'Show',
                showField: 'Show field(s)',
                disableSubmission: 'Disable submission',
                selectType: 'Select a type of result',
                logicType: 'Please select logic type.',
                pleaseEnterAMessageToDisplayWhenSubmissionIsPrevented:
                  'Please enter a message to display when submission is prevented',
                pleaseSelectFieldsToShowIfLogicCriteriaIsMet:
                  'Please select fields to show if logic criteria is met.',
                pleaseSelectLogicType: 'Please select logic type.',
              },
              newLogicBlock: {
                addLogic: 'Add logic',
              },
              deleteLogicModal: {
                deleteLogic: 'Delete logic',
                areYouSure:
                  'Are you sure you want to delete this logic? This action is not reversible.',
                yes: 'Yes, delete logic',
                no: "No, don't delete",
              },
              activeLogicBlock: {
                saveChange: 'Save changes',
              },
              inactiveLogicBlock: {
                if: 'If',
                and: 'and',
                thenShow: 'then show',
                thenDisable: 'then disable submission',
                logicDelete:
                  'This field was deleted and has been removed from your logic',
                allFieldsWereDeletedPleaseSelectAtLeastOneField:
                  'All fields were deleted, please select at least one field',
              },
            },
          },
          endPageDrawer: {
            editThank: 'Edit thank you page',
            cancel: 'Cancel',
            save: 'Save page',
            buttonRedirect: 'Button redirect link',
            buttonText: 'Button text',
            followInstruct: 'Follow-up instructions',
            title: 'Title',
            defaultLink: 'Default form link',
            reSubmit: 'Submit another response',
            pleaseEnterAValidURLStartWithHttpsOrHttp:
              'Please enter a valid URL (starting with https:// or http://)',
          },
        },
        preview: {
          PreviewFormProvider: {
            anErrorOccurredWhilstProcessingSubmission:
              'An error occurred whilst processing your submission. Please refresh and try again.',
          },
        },
        settings: {
          mutation: {
            formOpenCloseOff: 'Schedule opening/closing form is turned off.',
            formOpenCloseOn: 'Schedule opening/closing form is turned on.',
            formOpenUpdated: 'Schedule opening/closing form is updated.',
          },
          components: {
            generalTabHeader: {
              header: 'General settings',
            },
            formLimitToggle: {
              responseLimit: 'Set a response limit',
              maxRes: 'Maximum number of responses allowed',
              autoClosed:
                'Your form will automatically close once it reaches the set limit. Enable reCAPTCHA to prevent spam submissions from triggering this limit.',
              mustGreater:
                'Submission limit must be greater than current submission count',
            },
            formDateToggle: {
              openCloseForm: 'Set form opening/closing date',
              openCloseFormDescription:
                'The form will open/close based on the set date.',
              save: 'Save',
              openDate: 'Open:',
              closeDate: 'Close:',
              changeDate: 'Change date',
              chooseDate: 'Please choose when form opens and closed',
              openTime: 'Open Time',
              closeTime: 'Close Time',
            },
            formCustomisationSection: {
              mesClosedForm: 'Set message for closed form',
              mistake:
                'If you think this is a mistake, please contact the agency that gave you the form link.',
            },
            formCaptchaToggle: {
              enableCaptcha: 'Enable reCAPTCHA',
              captchaDescript:
                'If you expect non-English-speaking respondents, they may have difficulty understanding the reCAPTCHA selection instructions.',
            },
            formDetailsSection: {
              formName: 'Form name',
            },
            emailFormSection: {
              emailSent: 'Emails where responses will be sent',
              addRecep:
                'Add at least **2 recipients** to prevent loss of response. Learn more on [how to guard against email bounces]',
              sepEmail: 'Separate emails with a comma',
            },
            FormLanguageSection: {
              english: 'English',
              khmer: 'Khmer',
              setTheLanguageOfTheForm: 'Set the language of the form',
            },
            formScheduleToggle: {
              selectDateRange: 'Select date range',
              openTime: 'Open time',
              closeTime: 'Close time',
              save: 'save',
              to: 'to',
            },
            formStatusToggle: {
              yourForm: 'Your form is ',
              newRes: ' to new responses',
              open: 'OPEN',
              closed: 'CLOSED',
              inlineMessage:
                'Please turn off "Set form opening/closing date" feature to manually turn form on/off',
              thisFormCannotBeActivatedUntilAValidEServiceIDIsEnteredEtc:
                'This form cannot be activated until a valid e-service ID is entered in the Singpass section',
            },
            WebhooksSection: {
              enableRetries: 'Enable retries',
              description:
                'Your system must meet certain requirements before retries can be safely enabled. [Learn more]({GUIDE_WEBHOOKS})',
            },
            webhooksUnsupportedMsg: {
              unavail: 'Webhooks are not available in Email mode',
              webhookDescript:
                'Webhooks are useful for agencies who wish to have form response data sent directly to existing IT systems. This feature is only available in Storage Mode.',
              readMore: 'Read more about webhooks',
            },
            webhookURLInput: {
              endpoint: 'Endpoint URL',
              forDev:
                'For developers and IT officers usage. We will POST encrypted form responses in real-time to the HTTPS endpoint specified here.',
              pleaseEnterAValidURL:
                'Please enter a valid URL (starting with https://)',
            },
          },
          mutations: {
            useMutateFormSettings: {
              mutateFormLanguage: {
                formLanguageHasBeenUpdatedTo:
                  'Form language has been updated to { newFormLang }',
              },
              validationFailed: 'Validation failed',
            },
          },
          settingsPage: {
            settingGeneral: 'General',
            settingSingpass: 'Singpass',
            settingTwilio: 'Twilio credentials',
            settingWebhooks: 'Webhooks',
          },
        },
        responses: {
          feedBackPage: {
            emptyFeedback: {
              emptyFeedback: {
                h2: "You don't have any feedback yet",
                trySendingOutYourFormLink: 'Try sending out your form link!',
              },
            },
            emptyResponses: {
              emptyResponses: {
                h2: "You don't have any response yet",
                trySendingOutYourFormLink: 'Try sending out your form link!',
              },
            },
            feedbackPage: {
              average: 'Average Score',
              feedbackToDate: 'feedback submission to date',
            },
          },
          FeedbackPage: {
            FeedbackTable: {
              date: 'Date',
              feedback: 'Feedback',
              rating: 'Rating',
            },
          },
          IndividualResponsePage: {
            downloadNumAttachmentsAsZip:
              'Download { attachmentDownloadUrlsSize, plural, =0 {# attachment} =1 {# attachment} other {# attachments} } as .zip',
            FailedDownloadAttachment: 'Failed to download attachment',
            SuccessDownloadAttachmentFileName:
              'Successfully downloaded attachment {fileName} ',
            SuccessDownloadAttachmentFileZip:
              'Successfully downloaded {numAttachments} attachments as .zip',
          },
          responsePage: {
            secretKeyVerification: {
              enterOrUploadSecretKey: 'Enter or Upload Secret Key',
              secretKeyDownload:
                'Your Secret Key was downloaded when you created your form',
              unlockResponse: 'Unlock responses',
              cantFind: "Can't find your Secret Key?",
              responseToDate: 'response to date',
            },
          },
          ResponsesPage: {
            storage: {
              UnlockedResponses: {
                ResponsesTable: {
                  responseId: 'Response ID',
                  timestamp: 'Timestamp',
                },
                SubmissionSearchbar: {
                  searchByResponseId: 'Search by response ID',
                },
                UnlockedResponses: {
                  numberResultsFound:
                    '{ filteredCount, plural, =0 {results} =1 {result} other {results} } found',
                },
              },
            },
          },
          SummaryPage: {
            common: {
              EmptySummarizableResponses: {
                yourFormDoesntHaveAnyFieldThatCanBeSummarized:
                  "Your form doesn't have any field that can be summarized",
                tryAddingNewFieldsThatCanBeSummarized:
                  'Try adding new fields that can be summarized',
                summarizableFields:
                  'Section, Radio Button, Dropdown, Checkbox, Yes or No, Rating',
              },
            },
            components: {
              common: {
                EmptyChartSummary: {
                  nonSummarizableMessage:
                    'This field is affected by the change of the options or no value was submitted due to optional.',
                },
              },
            },
          },
          components: {
            formResultsNavbar: {
              formResultsNavbar: {
                response: 'Responses',
                feedback: 'Feedback',
                summary: 'Summary',
              },
              summaryChartSidebar: {
                pieChart: 'Pie Chart',
                verticalChart: 'vertical Chart',
                horizontalChart: 'Horizontal Chart',
                lineChart: 'Line Chart',
                areaChart: 'Area Chart',
              },
            },
            individualResponseStatusSection: {
              status: 'Status',
              pending: 'Pending',
              deprecated: 'Deprecated',
              rejected: 'Rejected',
              approved: 'Approved',
              loading: 'Loading...',
            },
          },
        },
        template: {
          UseTemplateModal: {
            UseTemplateWizardProvider: {
              title: '[Template] {formTitle}',
              modalHeader: 'Duplicate Form',
            },
          },
        },
      },
      login: {
        LoginPage: {
          slogan: 'Build secure government forms in minutes',
        },
        components: {
          LoginForm: {
            onlyAvailableForPublicOfficers:
              'Log in with a .gov.kh or other whitelisted email address',
            emailEmptyErrorMsg: 'Please enter an email address',
            login: 'Log in',
            failedToSendLoginOTPEtc:
              'Failed to send login OTP. Please try again later and if the problem persists, contact us.',
            haveAQuestion: 'Have a question?',
            thereWasAProblemWithYourInternetConnectionEtc:
              'There was a problem with your internet connection. Please check your network and try again. Network Error',
            thisIsNotAWhitelistedPublicServiceEmailDomainEtc:
              'This is not a whitelisted public service email domain. Please log in with your official government or government-linked email address.',
            validationFailed: 'Validation failed',
          },
          OtpForm: {
            otpSentTo: 'Enter OTP sent to',
            signIn: 'Sign in',
            otpIsRequired: 'OTP is required.',
            onlyNumber: 'Only numbers are allowed.',
            otp: 'Please enter a 6 digit OTP.',
          },
        },
      },
      workspace: {
        mutation: {
          delete: 'The form has been successfully deleted.',
        },
        components: {
          DeleteFormModal: {
            deleteForm: 'Delete form',
            loseResponseAndFeedback:
              'You will lose all responses and feedback for the following form permanently. Are you sure you want to delete the form?',
            yesDelete: 'Yes, delete form',
            cancel: 'Cancel',
          },
          AdminSwitchEnvMessage: {
            welcome: 'Welcome to the new FormKH! You can still',
            click: 'Click to switch to the original FormKH',
            avail: ' which is available until',
            switch: 'switch to the original one',
          },
          DuplicateFormModal: {
            DupeFormWizardProvider: {
              duplicate: 'Duplicate form',
            },
          },
          CreateFormModal: {
            CreateFormModalContent: {
              CreateFormDetailsScreen: {
                formName: 'Form name',
                itIsAdviseToUseShortSuccinctFormName:
                  'It is advised to use a shorter, more succinct form name.',
                howDoYouWantToReceiveFormResponse:
                  'How do you want to receive your form responses?',
                emailWhereResponsesWillBeSent:
                  'Emails where responses will be sent',
                nextStep: 'Next step',
              },
              FormResponseOptions: {
                storageMode: {
                  recommended: 'Recommended',
                  name: 'Storage Mode',
                  viewOrDownloadResponsesInFormKH:
                    'View or download responses in FormKH',
                  attachmentsUpTo20MBPerForm:
                    'Attachments: up to 20MB per form',
                  upToRestrictedAndSensitiveNormalData:
                    'Up to Restricted and Sensitive (Normal) data',
                  supportsWebhooksForResponses:
                    'Supports webhooks for responses',
                },
                emailMode: {
                  name: 'Email Mode',
                  receiveResponsesInYourBox: 'Receive responses in your inbox',
                  attachmentsUpTo7MBPerForm: 'Attachments: up to 7MB per form',
                  upToRestrictedAndSensitiveHighData:
                    'Up to Restricted and Sensitive (High) data',
                  supportsMyInfoFields: 'Supports MyInfo fields',
                },
              },
              SaveSecretKeyScreen: {
                description: {
                  downloadSecretKeyToProceed: 'Download Secret Key to proceed',
                  youllNeedItEveryTimeTillLoseItWithComma:
                    "You'll need it every time you access your responses to this form. If you lose it,",
                  allResponsesWillBePermanentlyLost:
                    'all responses will be permanently lost',
                  period: '.',
                  copy: 'Copy key',
                  copied: 'Copied!',
                },
                downloadKey: 'Download key',
                ifILoseMySecretKeyCheckBox:
                  'If I lose my Secret Key, I cannot activate my form or access any responses to it',
                iHaveSavedMyScretKeySafely: 'I have saved my Secret Key safely',
              },
            },
            CreateFormWizardProvider: {
              setupYourForm: 'Set up your form',
            },
          },
          EmptyWorkspace: {
            youDontHaveNoFormsYet: "You don't have any forms yet",
            getStartedByCreatingANewFormOrUseTemplate:
              'Start by creating a new form or using an existing template form.',
            createForm: 'Create form',
          },
          WorkSpaceHeader: {
            searchByTitle: 'Search by title',
            createForm: 'Create form',
            allForms: 'All forms',
            hasActiveSearchOrFilter:
              'Showing { displayedFormsCount } of { totalFormsCount, plural, =0 {# forms} =1 {# form} other {# forms} }',
          },
          WorkSpaceFormRow: {
            FormStatusLabel: {
              open: 'Open',
              closed: 'Closed',
            },
            RowActions: {
              RowActionsDrawer: {
                edit: 'Edit',
                preview: 'Preview',
                duplicate: 'Duplicate',
                shareForm: 'Share form',
                manageFormAdmins: 'Manage form admins',
                delete: 'Delete',
              },
            },
            WorkspaceFormRow: {
              edited: 'Edited',
              WorkspaceFormRowsFilterNone: {
                noFormFound: 'No forms found',
                tryAnotherSearchOrRemoveFilters:
                  'Try another search or remove filters',
              },
            },
          },
          WorkSpaceSearchBar: {
            filter: 'Filter',
          },
        },
        AdminNavBar: {
          featureRequest: 'Feature request',
          help: 'Help',
          whatNew: "What's New",
        },
        types: {
          allForms: 'All forms',
          openForms: 'Open forms',
          closedForms: 'Closed forms',
        },
      },
      FormTemplates: {
        FormTemplatesHeader: {
          templates: 'Templates',
          allFormTemplates: 'All form templates',
          dashboard: 'Dashboard',
          searchByTitle: 'Search by Title',
          hasActiveSearchOrFilter:
            'Showing { displayedFormTemplatesCount } of { totalFormTemplatesCount, plural, =0 {# template forms} =1 {# template form} other {# template forms} }',
        },
        EmptyFormTemplatesDashboard: {
          dontHaveNoTemplatesYet: 'No Templates Available',
          noTemplatesAvailableSubTittle:
            'Start creating a new form by going to the Dashboard',
          getStartedByCreatingANewForm: 'Get started by creating a new form',
          createForm: 'Create form',
          backToDashboard: 'Dashboard',
        },
      },
    },
    components: {
      Checkbox: {
        other: 'Others',
      },
      Field: {
        rating: {
          rating: {
            selected: 'selected',
          },
        },
        attachment: {
          attachment: {
            extension: "Your file's extension ending in *",
            notAllowed: 'is not allowed',
            singleFile: 'You can only upload a single file in this input',
            followingFile: 'The following file ',
            extensionZip: 'extension in your zip file ',
            notValid: 'is not valid:',
            errorParsing: 'An error has occurred whilst parsing your zip file',
            exceedLimit:
              'You have exceeded the limit, please upload a file below',
          },
        },
      },
      FormControl: {
        FormLabel: {
          optional: '(optional)',
        },
      },
      Dropdown: {
        components: {
          SelectMenu: {
            TextSelectedValidation: {
              maximum: 'Maximum',
              minimum: 'Minimum',
              exact: 'Exact',
            },
            DateSelectedValidation: {
              noPast: 'Disallow past dates',
              noFuture: 'Disallow future dates',
              custom: 'Custom date range',
            },
            RatingShape: {
              heart: 'Heart',
              star: 'Star',
            },
            YesNo: {
              yes: 'Yes',
              no: 'No',
            },
          },
        },
        MultiSelect: {
          clearSelection: 'Clear selection',
          noMatchingResults: 'No matching results',
        },
        SingleSelect: {
          clearSelection: 'Clear selection',
          noMatchingResults: 'No matching results',
        },
      },
    },
    shared: {
      types: {
        form: {
          formLogic: {
            LogicConditionState: {
              equal: 'is equals to',
              lte: 'is less than or equal to',
              gte: 'is more than or equal to',
              either: 'is either',
            },
          },
        },
      },
    },
  },
}
