import { LikertFieldQuestion } from '~shared/types/likert'

import { LIKERT_DELIMITER } from '../constants'

import { Response } from './Response.class'
import type { LikertArrayResponse } from './types'

export class LikertArrayAnswerResponse extends Response {
  private response: LikertArrayResponse

  constructor(responseData: LikertArrayResponse) {
    super(responseData)
    this.response = responseData
  }

  getAnswer(): string {
    return this.response.answerArray.join(LIKERT_DELIMITER)
  }

  get numCols(): number {
    return 1
  }

  get questionInfo(): LikertFieldQuestion {
    return this.response.questionInfo
  }
}
