import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import FileSaver from 'file-saver'

import {
  StorageModeSubmissionDto,
  SubmissionInfoUpdateDto,
  SubmissionStatus,
} from '~shared/types'

import { useToast } from '~hooks/useToast'

import { SubmissionData } from '~features/public-form/PublicFormContext'

import { updateSubmissionStatusInfo } from '../AdminSubmissionsService'
import { adminFormResponsesKeys } from '../queries'
import { AttachmentsDownloadMap } from '../ResponsesPage/storage/types'
import {
  downloadAndDecryptAttachment,
  downloadAndDecryptAttachmentsAsZip,
} from '../ResponsesPage/storage/utils/downloadAndDecryptAttachment'

import { updateSubmissionStatus } from './IndividualResponseService'

export const useMutateDownloadAttachments = () => {
  const toast = useToast({ status: 'success', isClosable: true })
  const { t } = useTranslation()

  const handleError = useCallback(
    (error: Error) => {
      toast.closeAll()
      const message = t(
        'features.adminForm.responses.IndividualResponsePage.FailedDownloadAttachment',
      )
      toast({
        description: message,
        status: 'danger',
      })
    },
    [toast],
  )

  const downloadAttachmentMutation = useMutation(
    async ({
      url,
      secretKey,
      fileName,
    }: {
      url: string
      secretKey: string
      fileName: string
    }) => {
      const byteArray = await downloadAndDecryptAttachment(url, secretKey)
      if (!byteArray) throw new Error('Invalid file')
      FileSaver.saveAs(new Blob([byteArray]), fileName)
      return fileName
    },
    {
      onSuccess: (fileName) => {
        toast.closeAll()
        const message = t(
          'features.adminForm.responses.IndividualResponsePage.SuccessDownloadAttachmentFileName',
          { fileName },
        )
        toast({ description: message })
      },
      onError: handleError,
    },
  )

  const downloadAttachmentsAsZipMutation = useMutation(
    async ({
      attachmentDownloadUrls,
      secretKey,
      fileName,
    }: {
      attachmentDownloadUrls: AttachmentsDownloadMap
      secretKey: string
      fileName: string
    }) => {
      const byteArray = await downloadAndDecryptAttachmentsAsZip(
        attachmentDownloadUrls,
        secretKey,
      )
      if (!byteArray) throw new Error('Invalid file')
      FileSaver.saveAs(new Blob([byteArray]), fileName)
      return attachmentDownloadUrls.size
    },
    {
      onSuccess: (numAttachments) => {
        toast.closeAll()
        const message = t(
          'features.adminForm.responses.IndividualResponsePage.SuccessDownloadAttachmentFileZip',
          { numAttachments },
        )
        toast({ description: message })
      },
      onError: handleError,
    },
  )

  return { downloadAttachmentMutation, downloadAttachmentsAsZipMutation }
}

export const useMutateSubmissionInfo = () => {
  const { formId, submissionId } = useParams()
  if (!formId) throw new Error('No formId provided')
  if (!submissionId) throw new Error('No submissionId provided')

  const queryClient = useQueryClient()
  const toast = useToast({ status: 'success', isClosable: true })

  const updateSubmissionInfoData = useCallback(
    (newData: SubmissionInfoUpdateDto) => {
      queryClient.setQueryData(
        adminFormResponsesKeys.individual(formId, submissionId),
        (oldData) =>
          oldData
            ? {
                ...oldData,
                ...newData,
              }
            : undefined,
      )
      queryClient.invalidateQueries(adminFormResponsesKeys.id(formId))
    },
    [formId, submissionId, queryClient],
  )

  const handleSuccess = useCallback(
    ({
      newData,
      toastDescription,
    }: {
      newData: SubmissionInfoUpdateDto
      toastDescription: React.ReactNode
    }) => {
      toast.closeAll()
      updateSubmissionInfoData(newData)

      // Show toast on success.
      toast({
        description: toastDescription,
      })
    },
    [toast, updateSubmissionInfoData],
  )

  // const handleError = useCallback(
  //   (error) => {
  //     toast.closeAll()
  //     toast({
  //       description: `Something went wrong with ${error}`,
  //       status: 'danger',
  //     })
  //   },
  //   [toast],
  // )

  const mutateSubmissionStatus = useMutation(
    (nextSubmissionStatus: SubmissionStatus) =>
      updateSubmissionStatus(formId, submissionId, nextSubmissionStatus),
    {
      onSuccess: (newData) => {
        handleSuccess({
          newData,
          toastDescription: `submission status has been updated`,
        })
      },
    },
  )

  // const mutateSubmissionStatusReason = useMutation(
  //   (nextSubmissionStatusReason: string) =>
  //     updateSubmissionStatusReason(
  //       formId,
  //       submissionId,
  //       nextSubmissionStatusReason,
  //     ),
  // )

  return {
    mutateSubmissionStatus,
    // mutateSubmissionStatusReason,
  }
}
