import { useMemo } from 'react'
import { DropzoneInputProps, DropzoneState } from 'react-dropzone'
import { chakra, Icon, Text, useStyles, VisuallyHidden } from '@chakra-ui/react'

import { BxsCloudUpload } from '~assets/icons/BxsCloudUpload'
import Link from '~components/Link'

import {
  publicI18nSelector,
  usePublicLanguageStore,
} from '~features/public-form/usePublicLanguageStore'

interface AttachmentDropzoneProps {
  inputProps: DropzoneInputProps
  isDragActive: DropzoneState['isDragActive']
  readableMaxSize?: string
  question?: string
}

export const AttachmentDropzone = ({
  inputProps,
  isDragActive,
  readableMaxSize,
  question,
}: AttachmentDropzoneProps): JSX.Element => {
  const styles = useStyles()
  const publicI18n = usePublicLanguageStore(
    useMemo(() => publicI18nSelector, []),
  )
  return (
    <>
      <VisuallyHidden>
        {question} Click to upload file, maximum file size of {readableMaxSize}
      </VisuallyHidden>
      <chakra.input {...inputProps} data-testid={inputProps.name} />
      <Icon aria-hidden as={BxsCloudUpload} __css={styles.icon} />

      {isDragActive ? (
        <Text aria-hidden>
          {publicI18n(
            'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.dropFileHere',
          )}
        </Text>
      ) : (
        <Text aria-hidden>
          <Link isDisabled={inputProps.disabled}>
            {publicI18n(
              'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.chooseFile',
            )}
          </Link>{' '}
          {publicI18n(
            'features.adminForm.create.builderAndDesign.BuilderAndDesignDrawer.EditFieldDrawer.editFieldType.EditFieldType.dragDrop',
          )}
        </Text>
      )}
    </>
  )
}
