import { Helmet } from 'react-helmet-async'
import { Link as ReactLink, useNavigate } from 'react-router-dom'
import { Box, chakra, Flex, SimpleGrid, Stack, Text } from '@chakra-ui/react'

import { AppFooter } from '~/app/AppFooter'
import i18n from '~/i18n/i18n'

import { BxsDashboard } from '~assets/icons/BxsDashboard'
import { useAuth } from '~contexts/AuthContext'
import { DASHBOARD_ROUTE } from '~constants/routes'
import { useIsMobile } from '~hooks/useIsMobile'
import Button from '~components/Button'
import Link from '~components/Link'

import { NotFoundSvgr } from './NotFoundSvgr'

export const NotFoundErrorPage = (): JSX.Element => {
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth()

  return (
    <>
      <Helmet title="Not found" />
      <Flex flex={1} flexDir="column" h="100%">
        <Flex
          justify="center"
          align="center"
          flexDir="column"
          flex={1}
          bgGradient={{
            base: 'linear(to-b, primary.500, primary.500 40%, primary.100 0)',
            md: 'linear(to-b, primary.500 50%, primary.100 50%)',
          }}
          py="3rem"
          px="1.5rem"
        >
          <NotFoundSvgr
            maxW="100%"
            mb={{ base: '1.5rem', md: '3rem' }}
            maxH={{ base: '220px', md: 'initial' }}
          />
          <Stack
            spacing="2.5rem"
            color="secondary.500"
            align="center"
            textAlign="center"
          >
            <Text as="h2" textStyle="h2">
              {i18n.t<string>(
                'NotFoundError.NotFoundErrorPage.notFoundErrorPage',
              )}
            </Text>

            <SimpleGrid columns={2} spacing={5}>
              <Button isFullWidth={isMobile} onClick={() => navigate(-1)}>
                {i18n.t<string>('NotFoundError.NotFoundErrorPage.back')}
              </Button>
              {isAuthenticated ? (
                <chakra.button
                  transitionProperty="common"
                  transitionDuration="normal"
                  as={ReactLink}
                  textDecorationLine="none"
                  to={`${DASHBOARD_ROUTE}`}
                  w={'full'}
                >
                  <Button
                    gridArea="dashboard"
                    leftIcon={<BxsDashboard fontSize="1.5rem" />}
                    bg={'white'}
                    color={'primary.500'}
                    w={'100%'}
                  >
                    {i18n.t<string>(
                      'NotFoundError.NotFoundErrorPage.goToDashboard',
                    )}
                  </Button>
                </chakra.button>
              ) : null}
            </SimpleGrid>
          </Stack>
        </Flex>
        <AppFooter />
      </Flex>
    </>
  )
}
